import React, { useEffect } from 'react'
import { Route, Routes } from '@remix-run/react'
import { useLocation, useNavigate } from '@remix-run/react'

import { useCurrentUser } from '../../graphql/hooks'
import UploadProfilePhotoStep from './OnboardingSteps/UploadProfilePhotoStep'
import AddMultiFactorAuthenticationStep from './OnboardingSteps/AddMultiFactorAuthStep'
import PostEventStep from './OnboardingSteps/PostEventStep'
import OnboardingComplete from './OnboardingComplete'

import * as styles from './Onboarding.styles'
import Overview from './Overview'
import ConnectStripeStep from './OnboardingSteps/ConnectStripeStep'
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner'
import { Page } from '../../components/Page/Page'
import { useOnboardingStepsQuery } from '../../graphql/queries/user/onboardingSteps.query.gen'
import { OnboardingStep, OnboardingStepName } from '../../graphql/types.gen'
import { Card } from '../../components/Card/Card'

export type OnboardingRouteElements = Partial<
  Record<OnboardingStep['name'], React.ReactNode>
>

export interface OnboardingStepsResult {
  currentStepIndex?: number
  currentStep?: OnboardingStep
  nextStep?: OnboardingStep
  allCompleted?: boolean
  onboardingSteps?: OnboardingStep[]
  queryResult?: ReturnType<typeof useOnboardingStepsQuery>
}

/**
 * Lets you know what the next step is so that we can navigate to it
 * while preserving a human-readable URL
 * */
export function useOnboardingSteps(): OnboardingStepsResult {
  const { user } = useCurrentUser()
  const { pathname } = useLocation()
  const queryResult = useOnboardingStepsQuery({ skip: !user })
  const { data } = queryResult

  if (!user) {
    return {}
  }

  const onboardingSteps = data ? data.onboardingSteps : []
  const allCompleted = !onboardingSteps.find((step) => !step.isCompleted)

  if (allCompleted) {
    return { allCompleted, onboardingSteps }
  }

  let currentStepIndex = 0

  const currentStepName = getStepNameFromPathName(pathname)

  if (currentStepName) {
    currentStepIndex = onboardingSteps.findIndex(
      (step) => step.name === currentStepName,
    )
  }

  // Wrap around to the first step if the value is higher than the total number of steps
  let nextStepIndex = currentStepIndex + 1
  if (nextStepIndex >= onboardingSteps.length) {
    nextStepIndex = 0
  }

  return {
    currentStepIndex,
    currentStep: onboardingSteps[currentStepIndex],
    nextStep: onboardingSteps[nextStepIndex],
    onboardingSteps,
    queryResult,
  }
}

function getStepNameFromPathName(
  pathname: string,
): OnboardingStepName | undefined {
  const pathFragments = pathname.split('/')
  const lastFragment = pathFragments[pathFragments.length - 1].toUpperCase()

  const stepNames = OnboardingStepName as Record<string, OnboardingStepName>
  const stepNameExists = Object.values(stepNames).includes(
    lastFragment as OnboardingStepName,
  )

  if (stepNameExists) {
    return lastFragment as OnboardingStepName
  }

  return undefined
}

function useRedirect(onboardingSteps?: OnboardingStep[]) {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (
      location.pathname === '/' ||
      location.pathname === '/onboarding' ||
      location.pathname === '/onboarding/*' ||
      location.pathname === '/onboarding/'
    ) {
      if (!onboardingSteps || onboardingSteps.length === 0) {
        return
      }

      const firstStep =
        onboardingSteps.find((step) => !step.isCompleted) ?? onboardingSteps[0]

      const firstStepRoute = `${firstStep.name}`
      navigate(firstStepRoute, { replace: true })
    }
  }, [onboardingSteps, location.pathname])
}

function OnboardingPage() {
  const onboardingStepResult = useOnboardingSteps()
  const { allCompleted, onboardingSteps, queryResult } = onboardingStepResult
  useRedirect(onboardingSteps)

  if (!queryResult || queryResult.loading) {
    return <LoadingSpinner />
  }

  if (allCompleted) {
    return (
      <Page title="Onboarding">
        {onboardingSteps && <Overview stepResult={onboardingStepResult} />}
        <OnboardingComplete />
      </Page>
    )
  }

  return (
    <Page title="Onboarding">
      <nav css={styles.overview}>
        {onboardingSteps && <Overview stepResult={onboardingStepResult} />}
      </nav>

      <Card composeCss={styles.content}>
        <Routes>
          <Route
            path={OnboardingStepName.ProfilePicture}
            element={<UploadProfilePhotoStep />}
          />
          <Route
            path={OnboardingStepName.MultiFactorAuthentication}
            element={<AddMultiFactorAuthenticationStep />}
          />
          <Route
            path={OnboardingStepName.PostEvent}
            element={<PostEventStep />}
          />
          <Route
            path={OnboardingStepName.ConnectStripeAccount}
            element={<ConnectStripeStep />}
          />
        </Routes>
      </Card>
    </Page>
  )
}

export default OnboardingPage
