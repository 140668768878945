import { css } from '@emotion/react'

import { mTheme } from '../../../style/themes'

const styles: Styles = {
  container: css({
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: mTheme.size.S,
  }),
  heading: css({
    paddingLeft: 0,
    margin: 0,
    justifySelf: 'start',
  }),
  description: css({
    margin: 0,
    marginBottom: mTheme.size.S,
    fontColor: mTheme.fontColor.plain.secondary,
  }),
  content: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: mTheme.size.XS,
  }),
}

interface OnboardingStoryContainerProps {
  children: React.ReactNode
  heading: string
  description?: string
  'data-testid': string
}

function StepContainer({
  children,
  heading,
  description,
  ...props
}: OnboardingStoryContainerProps) {
  return (
    <section css={styles.container} data-testid={props['data-testid']}>
      <h2 css={styles.heading}>{heading}</h2>
      {description && <p css={styles.description}>{description}</p>}
      <div css={styles.content}>{children}</div>
    </section>
  )
}

export default StepContainer
