import { useEffect } from 'react'
import StepContainer from './StepContainer'
import { ButtonAsAnchor } from '../../../components/Button'
import { Callout } from '../../../components/Callout'
import { StripeTestModeWarning } from './StripeTestModeWarning'
import { useGetOrCreateStripeAccountLinkMutation } from './getOrCreateStripeAccountLink.mut.gen'

function ConnectStripeStep() {
  const [getOrCreateStripeAccountLink, { loading, data }] =
    useGetOrCreateStripeAccountLinkMutation()

  useEffect(() => {
    getOrCreateStripeAccountLink()
  }, [])

  let error = data?.getOrCreateStripeAccountLink.error
  let link = data?.getOrCreateStripeAccountLink.ok

  return (
    <StepContainer
      heading="Connect to Stripe"
      description="To receive payouts from ticket earnings."
      data-testid="connect-stripe-account"
    >
      <StripeTestModeWarning>
        Once it's generally available, you'll need to re-connect your account.
      </StripeTestModeWarning>
      {error && <Callout variant="error">{error}</Callout>}
      <ButtonAsAnchor disabled={loading || Boolean(error)} href={link ?? ''}>
        Connect to Stripe <i className="fas fa-external-link" />
      </ButtonAsAnchor>
    </StepContainer>
  )
}

export default ConnectStripeStep
