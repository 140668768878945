import { ButtonAsLink } from '../Button'
import * as styles from './CreateEventButton.styles'

export const CreateEventButton: React.FC = () => {
  return (
    <ButtonAsLink
      css={styles.createEventButton}
      to="/event"
      primary
      prefetch="render"
    >
      Create an event
    </ButtonAsLink>
  )
}
