/* eslint-disable */
import * as Types from '../../types.gen'

import { gql } from '@apollo/client/index.js'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type OnboardingStepsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type OnboardingStepsQuery = {
  __typename?: 'Query'
  onboardingSteps: Array<{
    __typename?: 'OnboardingStep'
    name: Types.OnboardingStepName
    isCompleted: boolean
  }>
}

export const OnboardingStepsDocument = gql`
  query onboardingSteps {
    onboardingSteps {
      name
      isCompleted
    }
  }
`

/**
 * __useOnboardingStepsQuery__
 *
 * To run a query within a React component, call `useOnboardingStepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnboardingStepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnboardingStepsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOnboardingStepsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    OnboardingStepsQuery,
    OnboardingStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<OnboardingStepsQuery, OnboardingStepsQueryVariables>(
    OnboardingStepsDocument,
    options,
  )
}
export function useOnboardingStepsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OnboardingStepsQuery,
    OnboardingStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OnboardingStepsQuery,
    OnboardingStepsQueryVariables
  >(OnboardingStepsDocument, options)
}
export function useOnboardingStepsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    OnboardingStepsQuery,
    OnboardingStepsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OnboardingStepsQuery,
    OnboardingStepsQueryVariables
  >(OnboardingStepsDocument, options)
}
export type OnboardingStepsQueryHookResult = ReturnType<
  typeof useOnboardingStepsQuery
>
export type OnboardingStepsLazyQueryHookResult = ReturnType<
  typeof useOnboardingStepsLazyQuery
>
export type OnboardingStepsSuspenseQueryHookResult = ReturnType<
  typeof useOnboardingStepsSuspenseQuery
>
export type OnboardingStepsQueryResult = Apollo.QueryResult<
  OnboardingStepsQuery,
  OnboardingStepsQueryVariables
>
