import PictureDropzone from '../../../components/PictureDropzone'
import { getCdnUrl } from '../../../components/PictureDropzone/mediaUploads'
import { useCurrentUser } from '../../../graphql/hooks'
import currentUserQuery from '../../../graphql/queries/user/currentUser.query'
import StepContainer from './StepContainer'
import { useUpdateArtistMutation } from '../../../pages/Settings/ProfileSettings/ProfileDetails/ArtistDetails/updateArtistProfile.mut.gen'
import { useUpdateVenueProfileMutation } from '../../../pages/Settings/ProfileSettings/ProfileDetails/VenueDetails/venueDetailsProfile.mut.gen'
import { ClientOnly } from '../../../utilities/ClientOnly'

function UploadProfilePhotoStep() {
  const { selectedProfile } = useCurrentUser()
  const [updateArtistProfile] = useUpdateArtistMutation()
  const [updateVenueProfile] = useUpdateVenueProfileMutation()

  if (!selectedProfile) {
    return null
  }

  return (
    <StepContainer
      heading="Upload profile picture"
      data-testid="onboarding-upload-photo"
    >
      <ClientOnly>
        <PictureDropzone
          dragActiveCopy="Drop you new profile picture here"
          dragInactiveCopy="Add a new profile picture"
          onClear={() => null}
          onUploadingStatusChanged={() => null}
          onUploaded={async (uploadingResult) => {
            if ('ok' in uploadingResult) {
              const cdnUrl = getCdnUrl(uploadingResult.ok.result.variants[0])
              const profileUpdateOptions = {
                id: selectedProfile.id,
                picture: cdnUrl,
              }

              if (selectedProfile.__typename === 'Artist') {
                await updateArtistProfile({
                  variables: {
                    updateArtistProfileOptions: profileUpdateOptions,
                  },
                  refetchQueries: [{ query: currentUserQuery }],
                })
              } else if (selectedProfile.__typename === 'Venue') {
                await updateVenueProfile({
                  variables: {
                    updateVenueProfileOptions: profileUpdateOptions,
                  },
                  refetchQueries: [{ query: currentUserQuery }],
                })
              }
            }
          }}
        />
      </ClientOnly>
    </StepContainer>
  )
}

export default UploadProfilePhotoStep
