/* eslint-disable */
import * as Types from '../../../graphql/types.gen'

import { gql } from '@apollo/client/index.js'
import * as Apollo from '@apollo/client/index.js'
const defaultOptions = {} as const
export type GetOrCreateStripeAccountLinkMutationVariables = Types.Exact<{
  [key: string]: never
}>

export type GetOrCreateStripeAccountLinkMutation = {
  __typename?: 'Mutation'
  getOrCreateStripeAccountLink: {
    __typename?: 'StripeAccountLinkResult'
    ok?: string | null
    error?: Types.StripeAccountLinkError | null
  }
}

export const GetOrCreateStripeAccountLinkDocument = gql`
  mutation getOrCreateStripeAccountLink {
    getOrCreateStripeAccountLink {
      ok
      error
    }
  }
`
export type GetOrCreateStripeAccountLinkMutationFn = Apollo.MutationFunction<
  GetOrCreateStripeAccountLinkMutation,
  GetOrCreateStripeAccountLinkMutationVariables
>

/**
 * __useGetOrCreateStripeAccountLinkMutation__
 *
 * To run a mutation, you first call `useGetOrCreateStripeAccountLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateStripeAccountLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getOrCreateStripeAccountLinkMutation, { data, loading, error }] = useGetOrCreateStripeAccountLinkMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetOrCreateStripeAccountLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetOrCreateStripeAccountLinkMutation,
    GetOrCreateStripeAccountLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    GetOrCreateStripeAccountLinkMutation,
    GetOrCreateStripeAccountLinkMutationVariables
  >(GetOrCreateStripeAccountLinkDocument, options)
}
export type GetOrCreateStripeAccountLinkMutationHookResult = ReturnType<
  typeof useGetOrCreateStripeAccountLinkMutation
>
export type GetOrCreateStripeAccountLinkMutationResult =
  Apollo.MutationResult<GetOrCreateStripeAccountLinkMutation>
export type GetOrCreateStripeAccountLinkMutationOptions =
  Apollo.BaseMutationOptions<
    GetOrCreateStripeAccountLinkMutation,
    GetOrCreateStripeAccountLinkMutationVariables
  >
