import { OnboardingStepsResult } from './OnboardingPage'
import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'
import { ButtonAsLink } from '../../components/Button'
import { OnboardingStepName } from '../../graphql/types.gen'

const labels: Record<OnboardingStepName, string> = {
  [OnboardingStepName.PostEvent]: 'Post an event',
  [OnboardingStepName.ProfilePicture]: 'Upload a profile picture',
  [OnboardingStepName.MultiFactorAuthentication]:
    'Add multi-factor authentication',
  [OnboardingStepName.ConnectStripeAccount]: 'Connect to Stripe',
}

type Props = {
  stepResult: OnboardingStepsResult
}

const styles: Styles = {
  list: css({
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: mTheme.size.XS,
    width: '100%',
  }),
  link: css({
    display: 'block',
    width: '100%',
    fontWeight: 'normal',
    textAlign: 'left',
    background: mTheme.backgroundColor.secondary,

    '&:visited': {
      color: 'inherit',
    },
  }),
}

const stepStyle: StyleWithOptions<StepStyleOptions> =
  ({ isCurrent }) =>
  () => ({
    margin: 0,
    opacity: isCurrent ? 1.0 : 0.6,
  })

interface StepStyleOptions {
  isCompleted: boolean
  isCurrent: boolean
}

function Overview({ stepResult }: Props) {
  const { onboardingSteps, currentStep } = stepResult

  if (!onboardingSteps) {
    return null
  }

  return (
    <ul css={styles.list}>
      {onboardingSteps.map((step) => (
        <li
          css={stepStyle({
            isCompleted: step.isCompleted,
            isCurrent: currentStep?.name === step.name,
          })}
          key={step.name}
        >
          <ButtonAsLink
            to={step.name}
            css={styles.link}
            plain={step.isCompleted}
          >
            {step.isCompleted && <i className="fa fa-check" />}{' '}
            {labels[step.name]}{' '}
          </ButtonAsLink>
        </li>
      ))}
    </ul>
  )
}

export default Overview
