import StepContainer from './StepContainer'

function AddMultiFactorAuthenticationStep() {
  return (
    <StepContainer
      heading="Add multi-factor authentication"
      data-testid="onboarding-multi-factor-authentication"
    >
      todo: implement
    </StepContainer>
  )
}

export default AddMultiFactorAuthenticationStep
