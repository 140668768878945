import { ButtonAsLink } from '../../components/Button'
import { useCurrentUser } from '../../graphql/hooks'
import { getProfileUrl } from '../../utilities/userUtils'

function OnboardingComplete() {
  const { user } = useCurrentUser()
  if (!user) {
    return null
  }

  const { selectedProfile } = user
  if (!selectedProfile) {
    return null
  }

  return (
    <div data-testid="onboarding-complete">
      <h2>All done!</h2>
      <ButtonAsLink to={getProfileUrl(selectedProfile)}>
        Go to {selectedProfile.name}
      </ButtonAsLink>
    </div>
  )
}

export default OnboardingComplete
