import { CreateEventButton } from '../../../components/CreateEventButton/CreateEventButton'
import StepContainer from './StepContainer'

function PostEventStory() {
  return (
    <StepContainer heading="Post an event" data-testid="onboarding-post-event">
      <CreateEventButton />
    </StepContainer>
  )
}

export default PostEventStory
