import { css } from '@emotion/react'
import { mTheme } from '../../style/themes'

export const overview: Style = css({
  display: 'flex',
  flexDirection: 'column',
  gap: mTheme.size.S,
  backgroundColor: mTheme.backgroundColor.innerCard,
  padding: mTheme.size.S,
  borderRadius: mTheme.radius.primary,
})

export const heading: Style = css({
  display: 'flex',
  alignItems: 'center',
  fontSize: mTheme.fontSize.L,
  margin: 0,
})

export const content: Style = css({})
